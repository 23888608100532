import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Table, notification, Spin, Button, Modal, Select } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { BASE_API_URL } from '../util/constants';

const { Option } = Select;

const AllMaintenanceViewPage = () => {
  const [maintenanceRecords, setMaintenanceRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    const fetchMaintenanceRecords = async () => {
      try {
        const response = await axios.get(`${BASE_API_URL}/api/maintenance/all`);
        setMaintenanceRecords(response.data);
      } catch (error) {
        console.error('Error fetching maintenance records:', error);
        notification.error({ message: 'Error fetching maintenance records', description: error.message });
      } finally {
        setLoading(false);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${BASE_API_URL}/api/projects`);
        setProjects(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
        notification.error({ message: 'Error fetching projects', description: error.message });
      }
    };

    fetchMaintenanceRecords();
    fetchProjects();
  }, []);

  // Generate unique filter options from maintenanceRecords
  const projectTitleFilters = Array.from(new Set(maintenanceRecords.map(record => record.project_title)))
    .map(title => ({ text: title, value: title }));

  const usernameFilters = Array.from(new Set(maintenanceRecords.map(record => record.username)))
    .map(username => ({ text: username, value: username }));

  const createdAtFilters = Array.from(new Set(
    maintenanceRecords.map(record => moment(record.created_at).format('MMM - YYYY'))
  )).map(date => ({ text: date, value: date }));

  const handleAddClick = () => {
    setModalVisible(true);
  };

  const handleModalOk = () => {
    if (!selectedProject) {
      notification.warning({ message: 'Please select a project before proceeding.' });
      return;
    }
    navigate(`/maintenance/add/${selectedProject}`);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedProject(null);
  };

  const columns = [
    {
      title: 'Project Title',
      dataIndex: 'project_title',
      key: 'project_title',
      filters: projectTitleFilters,
      onFilter: (value, record) => record.project_title.includes(value),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      filters: usernameFilters,
      onFilter: (value, record) => record.username.includes(value),
    },
    {
      title: 'Updated WordPress Core',
      dataIndex: 'updated_wordpress_core',
      key: 'updated_wordpress_core',
      render: (value) => (value ? 'Yes' : 'No'),
    },
    {
      title: 'Updated Plugins',
      dataIndex: 'updated_plugins',
      key: 'updated_plugins',
      render: (value) => (value ? 'Yes' : 'No'),
    },
    {
      title: 'Updated JS Dependencies',
      dataIndex: 'updated_js_dependencies',
      key: 'updated_js_dependencies',
      render: (value) => (value ? 'Yes' : 'No'),
    },
    {
      title: 'Updated PHP Dependencies',
      dataIndex: 'updated_php_dependencies',
      key: 'updated_php_dependencies',
      render: (value) => (value ? 'Yes' : 'No'),
    },
    {
      title: 'Reviewed User Accounts',
      dataIndex: 'reviewed_user_accounts',
      key: 'reviewed_user_accounts',
      render: (value) => (value ? 'Yes' : 'No'),
    },
    {
      title: 'Changed Admin Password',
      dataIndex: 'changed_admin_password',
      key: 'changed_admin_password',
      render: (value) => (value ? 'Yes' : 'No'),
    },
    {
      title: 'Reviewed App Log',
      dataIndex: 'reviewed_app_log',
      key: 'reviewed_app_log',
      render: (value) => (value ? 'Yes' : 'No'),
    },
    {
      title: 'Reviewed Cron Log',
      dataIndex: 'reviewed_cron_log',
      key: 'reviewed_cron_log',
      render: (value) => (value ? 'Yes' : 'No'),
    },
    {
      title: 'Reviewed Error Log',
      dataIndex: 'reviewed_error_log',
      key: 'reviewed_error_log',
      render: (value) => (value ? 'Yes' : 'No'),
    },
    {
      title: 'Lighthouse Score Desktop (Now)',
      dataIndex: 'lighthouse_score_desktop_now',
      key: 'lighthouse_score_desktop_now'
    },
    {
      title: 'Lighthouse Score Mobile (Now)',
      dataIndex: 'lighthouse_score_mobile_now',
      key: 'lighthouse_score_mobile_now'
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => moment(date).format('MMM - YYYY'),
      filters: createdAtFilters,
      onFilter: (value, record) =>
        moment(record.created_at).format('MMM - YYYY') === value,
    },
  ];

  if (loading) {
    return <Spin size="large" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }} />;
  }

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h1 style={{ marginBottom: 0, color: 'black', fontSize: '24px' }}>
          All Maintenance Records
        </h1>
        <Button type="primary" onClick={handleAddClick}>
          Add
        </Button>
      </div>
      <Table
        columns={columns}
        pagination={true}
        dataSource={maintenanceRecords}
        rowKey="id"
        scroll={{ x: "max-content" }}
      />

      <Modal
        title="Select a Project"
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Go to Add Maintenance"
        cancelText="Cancel"
      >
        <Select
          style={{ width: '100%' }}
          showSearch
          placeholder="Select a project"
          filterOption={(input, option) =>
            option?.children.toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => setSelectedProject(value)}
        >
          {projects.map((project) => (
            <Option key={project.project_id} value={project.project_id}>
              {project.project_title}
            </Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default AllMaintenanceViewPage;
